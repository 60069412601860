.lectura {

  .qr-reader--scanner {
    width: 100%;
    min-width: 40vw;
    max-width: 500px;
    border: 1px solid red;
  }

  .qr--texto {
    font-size: 2em;
  }

  .qr--icon {
    font-size: 10em;
  }

  .qr--arrow {
    font-size: 3em;
  }

  .qr--info {
    min-width: 40vw;
  }

  .qr--version {
    font-size: 0.5em;
  }
}
